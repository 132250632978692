import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BusinessLogger,
	BusinessLoggerSymbol,
	IPageWillMountHandler,
	IPageWillUnmountHandler,
	IPropsStore,
	PageFeatureConfigSymbol,
	contextIdSymbol,
	Props,
	SiteFeatureConfigSymbol,
	WixBiSession,
	WixBiSessionSymbol,
	CurrentRouteInfoSymbol,
	TpaCompData,
} from '@wix/thunderbolt-symbols'
import { ITpa, ITpaComponentApi, TpaPageConfig } from './types'
import { name, TpaComponentApiSymbol } from './symbols'
import {
	name as tpaCommonsName,
	MasterPageTpaPropsCacheSymbol,
	MasterPageTpaPropsCache,
	TpaCommonsSiteConfig,
	TpaContextMappingSymbol,
	ITpaContextMapping,
	TpaSectionSymbol,
	ITpaSection,
	TpaSectionRegistry,
} from 'feature-tpa-commons'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import _ from 'lodash'
import * as ResponsiveChatUtils from './utils/responsiveChatUtils'
import { ICurrentRouteInfo } from 'feature-router'
import { IPageNumber, PageNumberSymbol } from 'feature-business-logger'

export const Tpa = withDependencies(
	[
		Props,
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		SessionManagerSymbol,
		BusinessLoggerSymbol,
		contextIdSymbol,
		MasterPageTpaPropsCacheSymbol,
		TpaContextMappingSymbol,
		WixBiSessionSymbol,
		CurrentRouteInfoSymbol,
		PageNumberSymbol,
		TpaSectionSymbol,
		TpaComponentApiSymbol,
	],
	(
		props: IPropsStore,
		{ widgetsClientSpecMapData }: TpaCommonsSiteConfig,
		{ widgets, tpaSectionIds, tpaInnerRouteConfig, pageId }: TpaPageConfig,
		sessionManager: ISessionManager,
		businessLogger: BusinessLogger,
		contextId: string,
		{ cacheProps, getCachedProps }: MasterPageTpaPropsCache,
		tpaContextMapping: ITpaContextMapping,
		wixBiSession: WixBiSession,
		currentRouteInfo: ICurrentRouteInfo,
		pageNumberHandler: IPageNumber,
		{ registerTpaSection, unregisterTpaSection }: ITpaSection,
		tpaComponentApi: ITpaComponentApi
	): IPageWillMountHandler & IPageWillUnmountHandler & ITpa => {
		const buildSrc = (compId: string) => {
			return tpaComponentApi.buildSrc({
				compId,
				tpaCompData: widgets[compId]!,
				pageId,
				tpaInnerRouteConfig,
			})
		}

		const tpaWidgetsToRegister = Object.entries(widgets)
			// Register ResponsiveChat under its template id, and not the viewer id
			.map(([id, tpaCompData]) => {
				const uniqueOrTemplateId = ResponsiveChatUtils.getTemplateOrUniqueId(id, tpaCompData)
				if (id !== uniqueOrTemplateId) {
					tpaContextMapping.registerTpaTemplateId(uniqueOrTemplateId, id)
				}
				return uniqueOrTemplateId
			})

		tpaContextMapping.registerTpasForContext(contextId, tpaWidgetsToRegister)
		const tpas = _.pickBy(widgets, ({ widgetId, isOOI }) => !isOOI && widgetsClientSpecMapData[widgetId])

		const getCompId = (id: string, { templateId }: TpaCompData) => templateId ?? id

		const rebuildTpaSrc = (compId: string) => {
			if (compId in tpas) {
				props.update({
					[compId]: {
						src: buildSrc(compId),
					},
				})
			}
		}

		const rebuildTpasSrc = () => {
			Object.keys(tpas).forEach(rebuildTpaSrc)
		}

		tpaSectionIds.forEach((id) => {
			// provide cross containers api for tpa sections
			const { appDefinitionId } = widgets[id]
			const entry: TpaSectionRegistry = {
				appDefinitionId,
				rebuildSrc: () => {
					rebuildTpaSrc(id)
				},
			}
			registerTpaSection(id, entry)
		})

		return {
			async pageWillMount() {
				sessionManager.addLoadNewSessionCallback(({ reason }) => {
					if (reason === 'memberLogin') {
						rebuildTpasSrc()
					}
				})
				Object.entries(tpas).forEach(([id, tpaCompData]) => {
					const { widgetId } = tpaCompData
					const reportIframeStartedLoading = _.once(() => {
						const routeInfo = currentRouteInfo.getCurrentRouteInfo()

						const now = Date.now()
						const tts = now - wixBiSession.initialRequestTimestamp
						businessLogger.logger.log(
							{
								appId: widgetsClientSpecMapData[widgetId].appDefinitionId,
								widget_id: widgetId,
								instance_id: getCompId(id, tpaCompData),
								src: 42,
								// APP_IFRAME_START_LOADING
								evid: 642,
								tts,
								pid: routeInfo ? routeInfo.pageId : null,
								pn: pageNumberHandler.getPageNumber(),
							},
							{ endpoint: 'ugc-viewer' }
						)
					})

					const defaultProps = tpaComponentApi.getDefaultProps(widgetId, reportIframeStartedLoading)
					const src = buildSrc(id)

					// Get cached props by template/uniqueId depending on if the comp is a responsive chat
					const templateOrUniqueId = ResponsiveChatUtils.getTemplateOrUniqueId(id, tpaCompData)
					const cachedProps = getCachedProps(templateOrUniqueId)

					props.update({
						[id]: {
							...defaultProps,
							src,
							...(cachedProps as any),
						},
					})
				})
			},
			pageWillUnmount() {
				if (pageId === 'masterPage') {
					Object.entries(tpas).forEach(([id]) => cacheProps(id))
				} else {
					// For chat to persist between navigations when isResponsive is true - we are caching its props
					// Even if its not in the master page
					Object.entries(tpas)
						.filter(([_id, tpaCompData]) => ResponsiveChatUtils.isResponsiveChat(tpaCompData))
						.forEach(([id, tpaCompData]) =>
							// Cache the already given props, by the viewer id, and save them under the template
							cacheProps(ResponsiveChatUtils.getTemplateOrUniqueId(id, tpaCompData), props.get(id))
						)
				}

				tpaSectionIds.forEach((id) => {
					unregisterTpaSection(id)
				})
			},
			rebuildTpasSrc,
		}
	}
)
