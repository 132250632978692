import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { ITpa, ITpaComponentApi, TpaPageConfig } from './types'
import { name, TpaComponentApiSymbol } from './symbols'
import { IPropsStore, PageFeatureConfigSymbol, Props } from '@wix/thunderbolt-symbols'

export const TpaDs = withDependencies(
	[named(PageFeatureConfigSymbol, name), TpaComponentApiSymbol, Props],
	(
		{ widgets, tpaInnerRouteConfig, pageId }: TpaPageConfig,
		tpaComponentApi: ITpaComponentApi,
		props: IPropsStore
	): ITpa => {
		const buildSrc = (compId: string) => {
			return tpaComponentApi.buildSrc({
				compId,
				tpaCompData: widgets[compId]!,
				pageId,
				tpaInnerRouteConfig,
			})
		}

		// TODO replace W/A with lifecycle hook or somethig when infra supports it
		setTimeout(() => {
			const compId = 'comp-kjscm8po'
			if (!props.get(compId)) {
				return
			}
			const src = buildSrc(compId)
			props.update({
				[compId]: {
					...tpaComponentApi.getDefaultProps('13677739-2c91-eeda-5485-f283815e0da7'),
					src,
				},
			})
		}, 1000)

		const rebuildTpasSrc = () => {}

		return {
			rebuildTpasSrc,
		}
	}
)
